/*
 *
 * COHESION CONSTANTS
 *
 */

/*
 * TAGULAR
 */
export const WRITE_KEY = process.env.environment === 'production' ? 'wk_1NT298Z1fu0BKyOCPNAXHDkZ9OP' : 'wk_24yH5wA4KRm7uk2m6DWo37rMSik';
export const SOURCE_KEY = process.env.environment === 'production' ? 'src_1NT297rret2SKU4MnEHx4eIxk2A' : 'src_24yH5vGUPks7O2KRIGUNCHZrR19';

/*
 * MONARCH
 */
export const MONARCH_SOURCE = '088adec0-65af-49ae-98b3-337ab58d4f6b';
export const MONARCH_TOKEN = 'e9584f8a-2739-486b-a11e-2b176f158cd2';

// Request test ruleset for local Cypress tests so we don't impact the model
export const CDAPI_TEST_RULESET = 'cDAPITestRuleset';
export const BAU_RULESET = '0840092c-673b-4d0f-9b22-74cb563000e8';
