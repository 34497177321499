// DO NOT EDIT THIS FILE. THIS FILE IS GENERATED IN NEXT.CONFIG.JS
import { lazy } from "react";

const listOfPreampAssets = {
  ['Banner | Address Check | Single Line']: lazy(() => import('./assets/Banner | Address Check | Single Line')),
  ['Banner | Address Check | Sticky | White']: lazy(() => import('./assets/Banner | Address Check | Sticky | White')),
  ['Banner | Address Check | White']: lazy(() => import('./assets/Banner | Address Check | White')),
  ['Banner | CTA | Neighborhood']: lazy(() => import('./assets/Banner | CTA | Neighborhood')),
  ['Banner | Countdown | Editable']: lazy(() => import('./assets/Banner | Countdown | Editable')),
  ['Banner | Days Countdown | Editable']: lazy(() => import('./assets/Banner | Days Countdown | Editable')),
  ['Banner | LTO (Bricks) | Editable']: lazy(() => import('./assets/Banner | LTO (Bricks) | Editable')),
  ['Banner | LTO | Editable']: lazy(() => import('./assets/Banner | LTO | Editable')),
  ['Banner | List | Expansion']: lazy(() => import('./assets/Banner | List | Expansion')),
  ['Hero | Address Check | 5G']: lazy(() => import('./assets/Hero | Address Check | 5G')),
  ['Hero | Address Check | Editable']: lazy(() => import('./assets/Hero | Address Check | Editable')),
  ['Hero | Address Check | YTTV']: lazy(() => import('./assets/Hero | Address Check | YTTV')),
  ['Hero | BAU (Bricks) | Editable']: lazy(() => import('./assets/Hero | BAU (Bricks) | Editable')),
  ['Hero | Carousel - 200mb + Wi-Fi 7']: lazy(() => import('./assets/Hero | Carousel - 200mb + Wi-Fi 7')),
  ['Hero | Carousel | Redesign - Editable']: lazy(() => import('./assets/Hero | Carousel | Redesign - Editable')),
  ['Hero | Carousel | Tabs - CNET Badge Summer Deal']: lazy(() => import('./assets/Hero | Carousel | Tabs - CNET Badge Summer Deal')),
  ['Hero | Content Only | Editable']: lazy(() => import('./assets/Hero | Content Only | Editable')),
  ['Hero | Content Only | Expansion']: lazy(() => import('./assets/Hero | Content Only | Expansion')),
  ['Hero | Content Only | Future is Fiber - Blue CTA']: lazy(() => import('./assets/Hero | Content Only | Future is Fiber - Blue CTA')),
  ['Hero | Content Only | Movers']: lazy(() => import('./assets/Hero | Content Only | Movers')),
  ['Hero | Content Only | Wi-Fi 7']: lazy(() => import('./assets/Hero | Content Only | Wi-Fi 7')),
  ['Hero | Editable']: lazy(() => import('./assets/Hero | Editable')),
  ['Hero | Price Point Split | P72 Editable']: lazy(() => import('./assets/Hero | Price Point Split | P72 Editable')),
  ['Hero | Price Point | Copper']: lazy(() => import('./assets/Hero | Price Point | Copper')),
  ['Hero | Price Point | Dynamic']: lazy(() => import('./assets/Hero | Price Point | Dynamic')),
  ['Hero | Price Point | Editable']: lazy(() => import('./assets/Hero | Price Point | Editable')),
  ['Hero | Price Point | Tiered - 5 Gig']: lazy(() => import('./assets/Hero | Price Point | Tiered - 5 Gig')),
  ['Hero | Price Point | Tiered - 5 Gig P72']: lazy(() => import('./assets/Hero | Price Point | Tiered - 5 Gig P72')),
  ['Hero | Vertical Hero (Bricks) | Editable']: lazy(() => import('./assets/Hero | Vertical Hero (Bricks) | Editable')),
  ['Modal | GDT']: lazy(() => import('./assets/Modal | GDT')),
  ['Modal | Return Visitor Refresh Dual CTA']: lazy(() => import('./assets/Modal | Return Visitor Refresh Dual CTA')),
  ['Modal | Return Visitor Refresh Image']: lazy(() => import('./assets/Modal | Return Visitor Refresh Image')),
  ['Section | Badge | Editable']: lazy(() => import('./assets/Section | Badge | Editable')),
  ['Section | Banner Headings | Editable']: lazy(() => import('./assets/Section | Banner Headings | Editable')),
  ['Section | Banner | YTTV']: lazy(() => import('./assets/Section | Banner | YTTV')),
  ['Section | Bento (Bricks) | Editable']: lazy(() => import('./assets/Section | Bento (Bricks) | Editable')),
  ['Section | CNET | Editable']: lazy(() => import('./assets/Section | CNET | Editable')),
  ['Section | Cabinet']: lazy(() => import('./assets/Section | Cabinet')),
  ['Section | Cabinet | Single - Editable']: lazy(() => import('./assets/Section | Cabinet | Single - Editable')),
  ['Section | Cards Carousel (Bricks) - Editable']: lazy(() => import('./assets/Section | Cards Carousel (Bricks) - Editable')),
  ['Section | Carousel | Cards Slim - Editable']: lazy(() => import('./assets/Section | Carousel | Cards Slim - Editable')),
  ['Section | Carousel | Fiber Merch Thumb']: lazy(() => import('./assets/Section | Carousel | Fiber Merch Thumb')),
  ['Section | Carousel | Movers Headline Thumb - YTTV Summer Deal']: lazy(() => import('./assets/Section | Carousel | Movers Headline Thumb - YTTV Summer Deal')),
  ['Section | Carousel | P72 Thumb Uncable Language']: lazy(() => import('./assets/Section | Carousel | P72 Thumb Uncable Language')),
  ['Section | Carousel | Slim Editable']: lazy(() => import('./assets/Section | Carousel | Slim Editable')),
  ['Section | Carousel | Thumb CDAPI']: lazy(() => import('./assets/Section | Carousel | Thumb CDAPI')),
  ['Section | Carousel | Thumb Expansion']: lazy(() => import('./assets/Section | Carousel | Thumb Expansion')),
  ['Section | Column Content | 5G']: lazy(() => import('./assets/Section | Column Content | 5G')),
  ['Section | Column Tiles (Bricks) | Editable']: lazy(() => import('./assets/Section | Column Tiles (Bricks) | Editable')),
  ['Section | Column Tiles (Bricks) | Reviews']: lazy(() => import('./assets/Section | Column Tiles (Bricks) | Reviews')),
  ['Section | Column Tiles | Copper']: lazy(() => import('./assets/Section | Column Tiles | Copper')),
  ['Section | Column Tiles | Editable']: lazy(() => import('./assets/Section | Column Tiles | Editable')),
  ['Section | Column Tiles | Fiber']: lazy(() => import('./assets/Section | Column Tiles | Fiber')),
  ['Section | Column Tiles | Movers']: lazy(() => import('./assets/Section | Column Tiles | Movers')),
  ['Section | Columns | Fiber']: lazy(() => import('./assets/Section | Columns | Fiber')),
  ['Section | Columns | Fiber Dark']: lazy(() => import('./assets/Section | Columns | Fiber Dark')),
  ['Section | Columns | YTTV']: lazy(() => import('./assets/Section | Columns | YTTV')),
  ['Section | Dynamic Speed Graph | Editable']: lazy(() => import('./assets/Section | Dynamic Speed Graph | Editable')),
  ['Section | Dynamic Speed Graph | Editable - ToBeArchieved']: lazy(() => import('./assets/Section | Dynamic Speed Graph | Editable - ToBeArchieved')),
  ['Section | Email Capture - Editable']: lazy(() => import('./assets/Section | Email Capture - Editable')),
  ['Section | Empty']: lazy(() => import('./assets/Section | Empty')),
  ['Section | FAQ (Bricks)']: lazy(() => import('./assets/Section | FAQ (Bricks)')),
  ['Section | FAQ | Copper']: lazy(() => import('./assets/Section | FAQ | Copper')),
  ['Section | FAQ | Movers']: lazy(() => import('./assets/Section | FAQ | Movers')),
  ['Section | GDT | CTA']: lazy(() => import('./assets/Section | GDT | CTA')),
  ['Section | GDT | Editable']: lazy(() => import('./assets/Section | GDT | Editable')),
  ['Section | GDT | QUIZ']: lazy(() => import('./assets/Section | GDT | QUIZ')),
  ['Section | Large Banner (Bricks) | Editable']: lazy(() => import('./assets/Section | Large Banner (Bricks) | Editable')),
  ['Section | Offers - Editable']: lazy(() => import('./assets/Section | Offers - Editable')),
  ['Section | Plan Features']: lazy(() => import('./assets/Section | Plan Features')),
  ['Section | Price Point | 5 Gig']: lazy(() => import('./assets/Section | Price Point | 5 Gig')),
  ['Section | Price Point | Copper']: lazy(() => import('./assets/Section | Price Point | Copper')),
  ['Section | Price Point | Editable']: lazy(() => import('./assets/Section | Price Point | Editable')),
  ['Section | Price Point | Headline Lead - Blue CTA']: lazy(() => import('./assets/Section | Price Point | Headline Lead - Blue CTA')),
  ['Section | Price Point | P72']: lazy(() => import('./assets/Section | Price Point | P72')),
  ['Section | Product Cards (Bricks) | Editable']: lazy(() => import('./assets/Section | Product Cards (Bricks) | Editable')),
  ['Section | Product Cards | CTA']: lazy(() => import('./assets/Section | Product Cards | CTA')),
  ['Section | Product Cards | Editable']: lazy(() => import('./assets/Section | Product Cards | Editable')),
  ['Section | Product Cards | Expansion - Editable']: lazy(() => import('./assets/Section | Product Cards | Expansion - Editable')),
  ['Section | Product Cards | Speed Visuals']: lazy(() => import('./assets/Section | Product Cards | Speed Visuals')),
  ['Section | Speed Visuals (Bricks) | Editable']: lazy(() => import('./assets/Section | Speed Visuals (Bricks) | Editable')),
  ['Section | Split Module (Bricks) | Editable']: lazy(() => import('./assets/Section | Split Module (Bricks) | Editable')),
  ['Section | Split Section | Do More + Built for Gaming']: lazy(() => import('./assets/Section | Split Section | Do More + Built for Gaming')),
  ['Section | Split Section | Eero + Frontier']: lazy(() => import('./assets/Section | Split Section | Eero + Frontier')),
  ['Section | Split Section | Expansion']: lazy(() => import('./assets/Section | Split Section | Expansion')),
  ['Section | Split Section | Future Proof Fiber - Blue CTA']: lazy(() => import('./assets/Section | Split Section | Future Proof Fiber - Blue CTA')),
  ['Section | Split Section | Gamers']: lazy(() => import('./assets/Section | Split Section | Gamers')),
  ['Section | Split Section | YTTV']: lazy(() => import('./assets/Section | Split Section | YTTV')),
  ['Section | Split Section | YTTV BOBO Summer Deal']: lazy(() => import('./assets/Section | Split Section | YTTV BOBO Summer Deal')),
  ['Section | Sticky CTA']: lazy(() => import('./assets/Section | Sticky CTA')),
  ['Section | Sticky CTA | GDT']: lazy(() => import('./assets/Section | Sticky CTA | GDT')),
  ['Section | Testimonials']: lazy(() => import('./assets/Section | Testimonials')),
  ['Section | VAS Cards | Editable']: lazy(() => import('./assets/Section | VAS Cards | Editable')),
  ['Section | Why Frontier']: lazy(() => import('./assets/Section | Why Frontier')),
  ['Sections | Fiber Features | Eero']: lazy(() => import('./assets/Sections | Fiber Features | Eero')),
};

export default listOfPreampAssets;
