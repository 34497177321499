/**
 * @module Fuel HOC
 * @description Central source providers, avoids having multiple nested providers.
 */
import {
  createContext, useEffect, useState
} from 'react';
import { ErrorBoundary } from '@red-digital/bricks';
import ErrorBoundaryComponent from '@/blocks/ErrorBoundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; // ReCaptcha Provider used to generate the token we pass in the 'frontierSiteDetailPredictive' cookie
import { useRouter } from 'next/router';
import {
  isCartHeavy, isPhoneHeavy, isResumeQuote, updateOLVersion
} from './utils/feature-flagging';
import useCartState from '@/hooks/useCartState';
import { DEFAULT_OPEN_HOURS } from '@/js/constants/landingPage';
import { getCookie } from '@/js/utils/cookie';
import { DEFAULT_FUSE_NUMBER } from '@/js/constants/cohesion';
import { MONARCH_RULESETS } from '@/js/constants/monarch';
import { requestMonarch } from '@/js/helpers/monarch';

/**
 * @function FuelContext
 * @description
 */
export const FuelContext = createContext();

/**
 * @function withFuel
 * @description
 */
export const withFuel = ( { App } ) => {
  const FuelProviderHOC = ( props ) => {
    const router = useRouter();
    const { clickThrough } = useCartState();

    const [ monarchProduct, setMonarchProduct ] = useState( 'fiber-500m' );
    const [ monarchCartHeavy, setMonarchCartHeavy ] = useState( false );
    const [ monarchPhoneHeavy, setMonarchPhoneHeavy ] = useState( false );
    const [ monarchResumeQuote, setMonarchResumeQuote ] = useState( false );
    const [ monarchOLVersion, setMonarchOLVersion ] = useState( 'v2' );
    const [ monarchOpenHours, setMonarchOpenHours ] = useState( [] );
    const [ fuseNumber, setFuseNumber ] = useState( null );

    const [ formInputted, setFormInputted ] = useState( false );

    useEffect( () => {
      cohesion( 'ready', () => {
        const apiVersion = getCookie( 'apiVersion' );
        requestMonarch( 'ruleset', MONARCH_RULESETS.LANDING_PAGE, { olVersion: apiVersion }, {}, ( response ) => {
          if ( ! response ) return;

          const {
            determineProduct, cartHeavy, phoneHeavy, determineResumeQuote, determineOLVersion, attendHours
          } = response;

          if ( typeof determineProduct === 'string' ) setMonarchProduct( determineProduct );

          if ( isCartHeavy( cartHeavy, router.query ) ) setMonarchCartHeavy( true );

          if ( isPhoneHeavy( phoneHeavy, router.query ) ) setMonarchPhoneHeavy( true );

          if ( isResumeQuote( determineResumeQuote ) ) setMonarchResumeQuote( true );

          if ( updateOLVersion( determineOLVersion ) ) setMonarchOLVersion( determineOLVersion );

          if ( attendHours?.data && Array.isArray( attendHours?.data ) ) {
            setMonarchOpenHours( attendHours.data );
          } else {
            setMonarchOpenHours( DEFAULT_OPEN_HOURS );
          }
        } );
      } );
    }, [ router, router.query ] );

    useEffect( () => {
      window?.cohesion( 'fuse:leasesReceived', ( leases ) => {
        setFuseNumber( leases?.[ 0 ]?.dnis );
      } );
      window?.cohesion(
        'fuse:error',
        () => setFuseNumber( DEFAULT_FUSE_NUMBER )
      );
    }, [] );

    return (
    <FuelContext.Provider value={ {
      monarchProduct,
      cartHeavy: monarchCartHeavy,
      phoneHeavy: monarchPhoneHeavy,
      clickThrough,
      resumeQuote: monarchResumeQuote,
      olVersion: monarchOLVersion,
      formInputted,
      setFormInputted,
      openHours: monarchOpenHours,
      fuseNumber
    } }>
      <ErrorBoundary production={ process.env.environment === 'production' } component={ ErrorBoundaryComponent }>
        <GoogleReCaptchaProvider useEnterprise reCaptchaKey={ `${ process.env.reCaptchaSiteKey }` } scriptProps={ { defer: true } }>
          <App { ...props } />
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </FuelContext.Provider>
    );
  };

  return FuelProviderHOC;
};

export default withFuel;
