/**
 * @module Monarch Helper
 * @description
 */
import { addPageAction } from '@/js/utils/new-relic';

export const requestMonarch = (
  type,
  id,
  payload = { _Monarch: { returnEvaluatedSubjects: true } },
  params = { context: '' },
  callback = ( c ) => c
) => {
  const response = monarch( type, id, payload, params, ( err, result ) => {
    if ( err ) {
      addPageAction( 'monarchError', { err } ); // report error to NR
      return null;
    }
    return callback( result );
  } );

  return response;
};
