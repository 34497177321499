import {
  WRITE_KEY, SOURCE_KEY,
  MONARCH_SOURCE,
  MONARCH_TOKEN
} from '@/js/constants/cohesion';

export const cohesionSnippet = {
  tagular: {
    apiKey: '14b35403-1631-4d6b-90e8-d407cc8c939c.ac505935-7947-44ac-935e-8bd7167385f8',
    writeKey: WRITE_KEY,
    sourceKey: SOURCE_KEY,
    multiparty: true,
    taggy: { enabled: true },
    cookieDomain: process.env.environment === 'production' ? 'frontier.com' : undefined
  },
  fuse: {
    leases: [ { pool: 'qEk6PrZcJoSyErMUQX2pLj' } ],
    metadata: {
      marketingCodeId: '3570001',
      vdn: '68231'
    }
  },
  monarch: {
    sourceId: MONARCH_SOURCE,
    token: MONARCH_TOKEN,
    endpoint: process.env.monarchDomain,
    simulatedEvents: {
      enabled: true
    },
    autoRequest: {
      enabled: true,
      suppressPlacements: false
    }
  },
  consent: {
    required: false
  }
};
