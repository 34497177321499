/**
 * @module Cookie
 * @description Utils for cookies
 */

/**
 * @function getCookies
 * @description Retrieve a cookie
 */
export const getCookies = () => {
  const pairs = document.cookie.split( ';' );
  const cookies = {};

  for ( let i = 0; i < pairs.length; i += 1 ) {
    const pair = pairs[ i ].split( '=' );
    cookies[ ( pair[ 0 ] ).trim() ] = unescape( pair[ 1 ] );
  }

  return cookies;
};

/**
 * @function getCookie
 * @description Retrieve a cookie
 */
export const getCookie = ( name ) => {
  const cookies = getCookies();
  const cookie = Object.keys( cookies ).filter( ( key ) => key === name );

  return cookies[ cookie ];
};

/**
 * @function setCookie
 * @description Set a cookie
 */
export const setCookie = ( name, value, exmins ) => {
  const d = new Date();
  const domain = new URL( window.location );
  const { hostname } = domain;

  if ( exmins !== 0 ) {
    d.setTime( d.getTime() + ( exmins * 60 * 1000 ) );
  }

  const expires = `expires=${ d.toUTCString() }`;
  document.cookie = `${ name }=${ value };${ exmins === 0 ? '' : expires };path=/;domain=${ hostname }`;
};

/**
 * @function deleteCookie
 * @description Delete a cookie
 */
export const deleteCookie = ( name ) => setCookie( name, '', 0 );
